import { hydratedNode, nodeTree } from "./tree"

const isTreeStarted = (tree: nodeTree) => {
    return (Object.values(tree.nodes).filter(e => !e.started).length === 0)
}

function canNodeStart(e: hydratedNode, tree: nodeTree) {
    return e.outputs.length === 0 || (() => {
        let canRender = true;
        e.outputs.forEach(f => f.handles.forEach(g => {
            if (!tree.nodes.filter(h => h.id === g.node)[0].started) {
                console.log(tree.nodes.filter(h => h.id === g.node)[0].avnode.title + " not rendered")
                canRender = false
            }
        }))
        return canRender;
    })()
}
/**
 * Starts a hydrated nodetree at a given time
 * @param tree the tree to start
 * @param time the time to start it (SECONDS SINCE CTX EPOCH)
 */
const startTree = (tree: nodeTree, time: number) => {
    if (Object.values(tree.nodes).filter(e => !e.rendered).length !== 0)
        console.error("not all nodes rendered")
    let i = 0;
    while (!isTreeStarted(tree)) {
        i++;
        if (i > 1000) {
            throw new Error("tree could not render")
        }
        const renderableNodes = tree.nodes.filter(node => !node.started && canNodeStart(node, tree))
        tree.nodes.forEach(e => {
            console.log(tree.nodes, e.avnode.title, e.outputs.map(f => f.handles.filter(g => !tree.nodes.filter(h => h.id === g.node)[0].started).length), canNodeStart(e, tree))
        })

        for (let i = 0; i < renderableNodes.length; i++) {
            const node = renderableNodes[i];
            if (node.start) { node.start(time); };
            node.started = true
        }
    }
    console.log("started")
}
const destroyTree = (tree: nodeTree) => {
    if (Object.values(tree.nodes).filter(e => !e.rendered).length !== 0)
        console.error("not all nodes rendered")
    Object.values(tree.nodes).forEach(e => { if (e.destroy) e.destroy() })
}

export { startTree, destroyTree }
import createSubscrib from '../util/subscrib';
import { Node, NodeTypes, Instance } from "react-flow-renderer"
import type AVNode from "../core/node"
import Styles from './nodeStyles';
import { nodeData } from './nodeData';
import createNode from './createNode';

/**
 * Renders an AVNode to a React flow Node
 * 
 * @param node The node to be rendered
 * @param position Where to render the node
 * @param id The id to render the node with
 * @param nodeTypes All the possible AVNode types; may be added to
 * @returns The node rendered for React-Flow
 */
export default function nodeToFlow(node: AVNode, position: { x: number, y: number }, id: number, nodeTypes: NodeTypes, getNode: Instance.GetNode<nodeData>): Node<nodeData> {

    const topHeight = node.body ? 50 + Styles.labelHeight : Styles.labelHeight;
    const height = topHeight + (node.inputs.length + node.outputs.length) * Styles.handleHeight + Styles.borderRadius;

    let nodeData: nodeData = {
        id: id,
        handles: [...node.inputs, ...node.outputs].map((e, i) => {
            return {
                title: e.name,
                id: JSON.stringify({ parentid: id.toString(), index: i, type: e.type.title })
            }
        }),
        inputs: node.inputs.map((e) => {
            return {
                val: createSubscrib(e.type.defaultState),
                getDefaultSetup: e.type.defaultSetup
            }
        })
    }



    if (!nodeTypes[node.title]) {
        nodeTypes[node.title] = createNode(node);
    }
    return {
        id: id.toString(),
        type: node.title,
        data: nodeData,
        style: { height, ...Styles.nodeStyle },
        position: position,
    }
}
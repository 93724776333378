import AudioParamHandle from "../handles/AudioNodeHandle";
import AVNode from "../node";
import inp from "./util/createInput";

const amp: AVNode = {
    setup: async (ctx, [outputs]) => {
        console.log("amp")
        console.log(outputs)
        let amp = ctx.createGain();
        amp.gain.value = 2;
        outputs.forEach((output) => {
            amp.connect(output.audioTarget, 0, output.input)
            amp.connect(amp.gain)
        })
        return { inputs: [inp(amp), inp(amp.gain)], destroy: () => { amp.disconnect(); } }
    },
    title: "amp", inputs: [{type: AudioParamHandle, name: "in"}, {type: AudioParamHandle, name: "gain"}], outputs: [{type: AudioParamHandle, name: "out"}]
}

export default amp;
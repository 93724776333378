import { useEffect, useState } from "react";
import { HandleType, ui, setup } from "../node";

type state = number;

const uistuff: {
    defaultState: state;
    UI?: ui<state>;
    defaultSetup: (p: state) => setup;
} = {
    defaultState: 1,
    UI: (p) => {
        const [val, setVal] = useState(p.val?.get);
        useEffect(() => {
            return p.val?.subscrib((e) => {
                console.log(e)
                setVal(e)
            })
        })
        const setValBetter = (e: state) => { p.val?.mod((_) => { return e }) };
        return (<>
            <p
                style={{ backgroundColor: 'black', position: "absolute", margin: 0, top: 0, left: 0, width: "50%" }}>
                {p.title}
            </p>
            {
                (!p.connected) ?
                    (<input
                        type="number"
                        value={val}
                        onChange={(e) => { setValBetter(+(e.target as any).value) }}
                        style={{ backgroundColor: 'white', border: 'none', position: "absolute", top: 0, left: "50%", width: "50%" }}
                    />) : null
            }
        </>)
    },
    defaultSetup: (state) => {
        const input: setup = async (cxt, [output]) => {
            console.log(output)
            console.log("state: " + state)
            output[0].value = state;
            return { inputs: [] }
        }
        return input;
    }
};

const AudioParamHandle: HandleType<state> = { title: "AudioNodeHandle", ...uistuff }
/**
 * @param input the third argument of AudioNode.connect
 * @param audioTarget the first argument of AudioNode.connect
 */
export type AudioParamType = {
    input: number | undefined,
    audioTarget: AudioNode
}
export default AudioParamHandle
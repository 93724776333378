import { setupType, setupTypeSpecial } from "./node";
import type { hydratedNode } from "./tree"

export default async function renderNode(node: hydratedNode, ctx: AudioContext) {
    const now = performance.now();
    const set = (await node.avnode.setup(ctx, node.outputs.map(e => e.handles.map(f => f.value)))) as setupType | setupTypeSpecial;
    console.log("loaded " + node.avnode.title + " in " + (performance.now() - now) + "ms");

    node.destroy = set.destroy;
    node.start = set.start;
    node.inputs = node.inputs.map((e, i) => { e.value = set.inputs[i]; return e; })
    if ((set as any).input)
        node.input = (set as any).input;

    // renders default params to inputs without input
    for (let i = 0; i++; i < node.inputs.length) {
        const input = node.inputs[i];
        if (input.handles.length === 0 && input.defaultSetup) {
            await input.defaultSetup(ctx, [[input.value]])
        }
    }

    node.rendered = true;
}
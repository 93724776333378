import AudioParamHandle from "../handles/AudioNodeHandle";
import { SpecialAVNode } from "../node";
import getWorklet from "./util/getWorklet";

const input: SpecialAVNode = {
    setup: async (ctx, [pitchOuts, startVelOuts, stopVelOuts, stopTimeOuts]) => {
        const inputNode = await getWorklet(ctx, "input")
        pitchOuts.forEach((e) => {
            inputNode.connect(e.audioTarget, 0, e.input)
        })
        startVelOuts.forEach((e) => {
            inputNode.connect(e.audioTarget, 1, e.input)
        })
        stopVelOuts.forEach((e) => {
            inputNode.connect(e.audioTarget, 2, e.input)
        })
        stopTimeOuts.forEach((e) => {
            inputNode.connect(e.audioTarget, 3, e.input)
        })
        return {
            inputs: [], start: (time: number) => { inputNode.start(time); },
            input: (input) => {
                inputNode.send("input", JSON.stringify(input));
            },
            destroy: () => { inputNode.stop(ctx.currentTime) }
        }
    },
    title: "input", inputs: [], outputs: ["pitch", "startVelocity", "stopVelocity", "stopTime"].map(e => { return { type: AudioParamHandle, name: e } })
}

export default input;
import React, { useState } from 'react';
import { ReactFlowProvider } from 'react-flow-renderer';
import './App.css';
import Demo from './components/Demo/Demo';
import Flow from './components/Flow/Flow';

let midi: WebMidi.MIDIAccess;

function App() {
  const [show, setShow] = useState(false)
  return (
    <div style={{ width: "100vw", height: "100vh", margin: "0px", padding: "0px", position: "fixed", top: 0, left: 0 }} className="App">
      {(show) ? <ReactFlowProvider><Flow /* midi={midi} */ /></ReactFlowProvider> : <button onClick={async () => {/* midi = (await navigator.requestMIDIAccess()); */ setShow(true) }}>show</button>}
    </div>
  );
}

export default App;

import AVNode from '../node';

import amp from './amp';
import multiply from './multiply';
import osc from './osc';
import output from './output';
import time from './time'
import input from './input'

export { default as amp } from './amp';
export { default as multiply } from './multiply';
export { default as osc } from './osc';
export { default as output } from './output';
export { default as time } from './time';
export { default as input } from './input';

const nodes: AVNode[] = [amp, osc, multiply, output, time, input];

export const getNodeByTitle = (title: string): AVNode => {
    let [node]: AVNode[] = nodes.filter((e) => e.title === title);
    return node;
};

export default nodes;
import AudioParamHandle from "../handles/AudioNodeHandle";
import AVNode from "../node";
import inp from "./util/createInput";

const osc: AVNode = {
    setup: async (ctx, [outputs]) => {
        console.log("osc", outputs)
        let osc = ctx.createOscillator();
        osc.type = 'sine';
        osc.frequency.value = 440;
        console.log(outputs);
        outputs.forEach((output) => {
            osc.connect(output.audioTarget, undefined, output.input)
        })
        return {
            inputs: [inp(osc.frequency)],
            start: (time: number) => { osc.start(time); },
            destroy: () => { osc.stop(); osc.disconnect(); console.log("destroy"); }
        }
    },
    title: "sine", inputs: [{type: AudioParamHandle, name: "pitch"}], outputs: [{ type: AudioParamHandle, name: "out" }]
}

export default osc;
import { memo, ReactElement } from 'react';
import { nodeData } from './nodeData';
import { nodeHandle } from './nodeHandleID';
import type { HandleType, setup } from "../core/node"
import { Handle, Node, NodeTypes, Position, Connection, Instance } from "react-flow-renderer";
import objEquals from '../util/objEquals';
import AVNode from '../core/node';
import Styles from './nodeStyles';

/**
 * Returns a function that validates a connection for a given handle type
 * @param p The type of the Handle
 * @returns a function that returns true if a Connection agrees with the handleType
 */
const isType = (p: HandleType<any | null | undefined>): (p: Connection) => boolean => {
    return (e) => {
        return (e.sourceHandle != null) && objEquals((JSON.parse(e.sourceHandle) as nodeHandle).type, p.title)
    }
}

export default function createNode(node: AVNode) {
    // Styles
    const topHeight = node.body ? 50 + Styles.labelHeight : Styles.labelHeight;
    const getHandleTop = (index: number) => {
        const topdist = (index + 0.5) * Styles.handleHeight;
        index++;
        return topHeight + topdist;
    }
    const Label = memo(() => {
        return (
            <div style={{ ...Styles.labelStyle }}>
                {node.title}
            </div>
        )
    })
    const handleuis = [...node.inputs.map((e, i) => {
        const defaultui = (e.type.UI) ? e.type.UI : e.name
        return defaultui
    }), ...node.outputs.map((e) => {
        const defaultui = e.name
        return defaultui;
    })]
    const HandleLabels = memo((p: { data: nodeData }) => {
        const paddingV = 5;
        const handles = [...node.inputs, ...node.outputs]
        return (
            <>
                {
                    handles.map((e, i) => {
                        let handlelabel: ReactElement | string = e.name;
                        const top = getHandleTop(i) + paddingV;
                        const handleui = handleuis[i];
                        if (handleui && typeof handleui != "string") handlelabel = handleui({ title: p.data.handles[i].title, connected: false, val: p.data.inputs[i].val })
                        return <div style={{ top, ...Styles.handleLabelStyle }} key={e.name + i}>
                            {handlelabel}
                        </div>
                    })
                }
            </>
        )
    })
    return memo((p: { data: nodeData, isConnectable: boolean }) => {
        const inputs = node.inputs.map((e, i) => {
            let handleid: nodeHandle = { parentid: p.data.id.toString(), index: i, type: e.type.title }
            return (
                <Handle
                    type={"target"}
                    id={JSON.stringify(handleid)}
                    position={Position.Left}
                    isValidConnection={isType(e.type)}
                    isConnectable={p.isConnectable}
                    key={"I" + i}
                    style={{ top: getHandleTop(i) }}
                />
            )
        })
        const outputs = node.outputs.map((e, i) => {
            let handleid: nodeHandle = { parentid: p.data.id.toString(), index: i, type: e.type.title }
            return (
                <Handle
                    type={"source"}
                    id={JSON.stringify(handleid)}
                    position={Position.Right}
                    isValidConnection={isType(e.type)}
                    isConnectable={p.isConnectable}
                    key={"O" + i}
                    style={{ top: getHandleTop(i + inputs.length) }}
                />
            )
        })

        return (
            <>
                <Label />
                <HandleLabels data={p.data} />
                {inputs}
                {outputs}
            </>
        )
    })
}
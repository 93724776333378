import { hydratedNode, nodeTree } from "./tree";

const shakeTree = (tree: nodeTree) => {
    console.log(tree.nodes.map(e => e))
    // remove unness nodes
    const newnodes = tree.nodes.map(e => { return { ...e, remove: true } })
    const markRender = (nodle: hydratedNode & { remove: boolean }) => {
        nodle.remove = false;
        console.log(nodle)
        nodle.inputs.forEach(connections => {
            connections.handles.forEach(outref => {
                newnodes.forEach(anyNode => {
                    if (anyNode.id === outref.node) {
                        if (anyNode.remove) {
                            markRender(anyNode);
                        }
                    }
                })
            })
        })
    }
    tree.nodes.forEach(e => console.log(e.avnode.title, e.avnode.title === "out"))
    console.log("outs: ", JSON.stringify(tree.nodes.filter(e => e.avnode.title === "out")))
    const outnode = newnodes.filter(e => e.avnode.title === "out")[0];
    console.log(outnode)
    markRender(outnode)
    tree.nodes = newnodes.filter(e => !e.remove)
    // remove unness references
    tree.nodes = tree.nodes.map(node => {
        node.outputs = node.outputs.map(output => {
            output.handles = output.handles.filter(handle => {
                let isin = false;

                tree.nodes.forEach(existingNode => {
                    if (existingNode.id === handle.node)
                        isin = true
                })

                return isin;
            })

            return output
        })

        return node
    })
    console.log(tree.nodes.map(e => e))
}
export default shakeTree;
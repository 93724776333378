import type { Node, Edge } from 'react-flow-renderer';
import type { outputhandleinstance, nodeTree, inputhandleinstance } from "../core/tree"
import { getNodeByTitle as getNodeType } from "../core/nodes/index";
import { nodeData } from "./nodeData";
import { nodeHandle } from "./nodeHandleID";

const flowToTree = (nodes: Node<nodeData>[], edges: Edge[]): nodeTree => {
    return {
        nodes: nodes.map((node) => {
            // gets the avnode
            const avnode = getNodeType(node.type as string);
            // get nodes this node outputs to
            const outputEdges = edges.filter(e => e.source === node.id);
            const outputs: outputhandleinstance[] = avnode.outputs.map((e, i) => {
                return {
                    type: e.type,
                    handles: outputEdges.filter(e => e.sourceHandle === node.data.handles[i].id).map(edge => {
                        return {
                            node: edge.target,
                            handleIndex: (JSON.parse(edge.targetHandle as string) as nodeHandle).index
                        }
                    })
                }
            })
            // get nodes from which this node recieves input
            const inputEdges = edges.filter(e => e.target === node.id)
            const inputs: inputhandleinstance[] = avnode.inputs.map((e, i) => {
                return {
                    type: e.type,
                    defaultSetup: node.data.inputs[i].getDefaultSetup(node.data.inputs[i].val?.get()),
                    handles: inputEdges.filter(e => e.targetHandle === node.data.handles[i].id).map(edge => {
                        return {
                            node: edge.source,
                            handleIndex: (JSON.parse(edge.sourceHandle as string) as nodeHandle).index
                        }
                    })
                }
            })
            // return node info
            return {
                id: node.id,
                inputs,
                outputs,
                avnode: avnode
            }
        })
    }
}
export default flowToTree;
const createSubscrib = (state) => {
    let num = 0;
    let subscribs = new Map();
    return {
        get: () => { return state; },
        mod: (mod) => {
            const newstate = mod(state);
            if (newstate) {
                state = newstate;
            }
            subscribs.forEach((e) => { e(state); });
        },
        subscrib: (subscrib) => {
            num++;
            subscribs.set(num, subscrib);
            return () => {
                subscribs.delete(num.valueOf());
            };
        }
    };
};
export default createSubscrib;

import AudioWorkletNodeExtended from "./AudioWorkletNodeExtended";

let gotScripts = false;
let worklets: {
    [name: string]: {
        name: string,
        inputs: number,
        outputs: number
    }
} = {}

export default async function getWorklet(ctx: AudioContext, name: string = 'osc'): Promise<AudioWorkletNodeExtended> {
    const create = () => {
        const worklet = new AudioWorkletNodeExtended(ctx, name, { numberOfInputs: worklets[name].inputs, numberOfOutputs: worklets[name].outputs});
        worklet.port.start()
        return worklet;
    }
    if (!gotScripts) {
        await ctx.audioWorklet.addModule('./dsps/worklets.js')
        // eslint-disable-next-line no-eval
        eval(await (await (await fetch("./dsps/getWorklet.js")).blob()).text());
        worklets = JSON.parse(sessionStorage.getItem("worklets") as string);
        gotScripts = !!worklets;
    }
    return create()
}
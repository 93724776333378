import AudioParamHandle from "../handles/AudioNodeHandle";
import AVNode from "../node";
import getWorklet from "./util/getWorklet";

const time: AVNode = {
    setup: async (ctx, [outputs]) => {
        const timeNode =  await getWorklet(ctx, "time")
        outputs.forEach((e) => {
            timeNode.connect(e.audioTarget, 0, e.input)
        })
        return { 
            inputs: [], start: (time: number) => { timeNode.start(time) }, 
            destroy: () => { timeNode.stop(ctx.currentTime) } 
        }
    },
    title: "time", inputs: [], outputs: [{ type: AudioParamHandle, name: "seconds" }]
}

export default time;
import React from "react";

const width = 160;
const borderRadius = 12;
const labelHeight = 28;
const handleHeight = 40;
const paddingH = 5;
const paddingV = 7;
const grey = "#A7A7A7";

const labelStyle: React.CSSProperties = {
    width: width,
    height: "fit-content",
    paddingInline: paddingH,
    fontFamily: "Poppins",
    fontSize: 16,
    color: "white",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    backdropFilter: "blur(5px)",
    position: "fixed",
    top: -30,
    left: -paddingH,
    textAlign: "left",
    textAnchor: 'start',
    borderRadius: 5
}
const handleLabelStyle: React.CSSProperties = {
    transform: "translateY(-50%)",
    position: 'fixed',
    left: paddingH,
    width: width - 2 * paddingH,
    height: "fit-content",
    backgroundColor: 'grey',
    color: "white"
};

const nodeStyle = { boxShadow: "0 0 0 1.6px " + grey, borderRadius, width, backgroundColor: "black" }

const Styles = { width, borderRadius, labelHeight, handleHeight, paddingH, paddingV, labelStyle, handleLabelStyle, nodeStyle };

export default Styles
import { input, inputEvent } from "./node";
import renderNode from "./renderNode"
import { startTree } from "./runTree";
import type { hydratedNode, nodeTree } from "./tree"

function canNodeRender(e: hydratedNode) {
    // the outputs which have all rendered handles must have the same length as the outputs
    return e.outputs.length === 0 || e.outputs.filter(f => f.handles.filter(g => g.value === undefined).length === 0).length === e.outputs.length;
}

export interface treeInput {
    start: (time: number, velocity: number, pitch: number) => void;
    release: (time: number, velocity: number, pitch: number) => void;
}

function isTreeRendered(tree: nodeTree) {
    // filters through each node to see if it is rendered
    const unrenderedNodes = tree.nodes.filter(e => {
        return !e.rendered;
    })
    return unrenderedNodes.length === 0
}

export default async function renderTree(tree: nodeTree, ctx: AudioContext): Promise<treeInput> {
    while (!isTreeRendered(tree)) {
        const renderableNodes = tree.nodes.filter(node => !node.rendered && canNodeRender(node))

        for (let i = 0; i < renderableNodes.length; i++) {
            const node = renderableNodes[i]
            await renderNode(node, ctx);
            // push outputs to tree
            tree.nodes.forEach((inputNode) => {
                inputNode.outputs = inputNode.outputs.map((output) => {
                    output.handles.filter(handle => handle.node === node.id).map(handle => {
                        handle.value = node.inputs[handle.handleIndex].value;
                        return handle;
                    })
                    return output;
                })
            })
        }
    }
    let input: input;
    return {
        start(time, velocity, pitch) {
            input = {
                start: {
                    time,
                    pitch,
                    velocity
                },
                release: {
                    time: 0,
                    velocity: 0
                }
            }
            tree.nodes.forEach(e => {
                if (e.input)
                    e.input(input)
            })
            startTree(tree, time);
        },
        release(time, velocity) {
            tree.nodes.forEach(e => {
                if (e.input)
                    e.input({
                        ...input,
                        release: {
                            time,
                            velocity
                        }
                    })
            })
        },
    }
}
import "./interface/flow.css"
import { useState } from 'react';
import renderVNode from './interface/instantiateNode';
import ReactFlow, {
    addEdge,
    NodeTypes,
    Node,
    Edge,
    useNodesState,
    useEdgesState,
    Connection,
    useReactFlow,
} from 'react-flow-renderer';
// nodes
import * as Nodes from "./core/nodes/index"
// create tree
import flowToTree from './interface/flowToTree';
// treeops
import shakeTree from './core/shakeTree';
import renderTree from './core/renderTree';
import { destroyTree } from './core/runTree';
import { ThemeProvider } from 'styled-components';

let nodeTypes: NodeTypes = {}

const initialEdges: Edge[] = [];

function Flow() {
    const flow = useReactFlow();

    const initialNodes: Node[] = [
        renderVNode(Nodes.osc, { x: 50, y: 50 }, 2, nodeTypes, flow.getNode),
        renderVNode(Nodes.multiply, { x: 150, y: 250 }, 3, nodeTypes, flow.getNode),
        renderVNode(Nodes.output, { x: 50, y: 450 }, 4, nodeTypes, flow.getNode),
        renderVNode(Nodes.time, { x: 150, y: 650 }, 5, nodeTypes, flow.getNode),
        renderVNode(Nodes.multiply, { x: 150, y: 250 }, 6, nodeTypes, flow.getNode),
        renderVNode(Nodes.input, { x: 200, y: 250 }, 7, nodeTypes, flow.getNode),
    ];

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
    const onConnect = (params: Connection) => setEdges((eds) => addEdge(params, eds));
    const ctx = useState(() => new AudioContext())[0];

    window.onkeydown = () => {
        // console.log(nodes, edges, nodeTypes)
        const tree = flowToTree(nodes, edges)
        // console.log(flowToTree(nodes, edges))
        shakeTree(tree)
        // console.log(tree)
        const render = async () => {
            const now = performance.now();
            const input = await renderTree(tree, ctx)
            console.log("rendered", "in: " + (performance.now() - now) + "ms")
            // console.log(tree)
            input.start(ctx.currentTime + 0.003, 1, 0);
        }
        const prom = render()
        setTimeout(() => { prom.then(() => { destroyTree(tree) }) }, 2000)
    }
    return <ThemeProvider theme={{
        bg: '#000',
        primary: '#ff0072',

        nodeBg: '#343435',
        nodeColor: '#f9f9f9',
        nodeBorder: '#888',

        minimapMaskBg: '#343435',

        controlsBg: '#555',
        controlsBgHover: '#676768',
        controlsColor: '#dddddd',
        controlsBorder: '#676768',
    }}>
        <ReactFlow
            nodes={nodes}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            edges={edges}
            nodeTypes={nodeTypes}
            fitView
        />
    </ThemeProvider >;
}

export default Flow;
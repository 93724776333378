import AudioParamHandle from "../handles/AudioNodeHandle";
import AVNode from "../node";
import inp from "./util/createInput";
import getWorklet from "./util/getWorklet";

const multiply: AVNode = {
    setup: async (ctx, [outputs]) => {
        console.log("mult", outputs)
        const multiplyNode = await getWorklet(ctx, "multiply")
        outputs.forEach((e) => {
            multiplyNode.connect(e.audioTarget, 0, e.input)
        })
        console.log([inp(multiplyNode, 0), inp(multiplyNode, 1)])
        return { 
            inputs: [inp(multiplyNode, 0), inp(multiplyNode, 1)], start: (time: number) => { multiplyNode.start(time);  }, 
            destroy: () => { multiplyNode.stop(ctx.currentTime) } 
        }
    },
    title: "multiply", inputs: [{ type: AudioParamHandle, name: "one" }, { type: AudioParamHandle, name: "two" }], outputs: [{ type: AudioParamHandle, name: "seconds" }]
}

export default multiply;
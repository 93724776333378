import AudioParamHandle from "../handles/AudioNodeHandle";
import AVNode from "../node";

const output: AVNode = {
    setup: async (ctx: AudioContext, _) => {
        console.log("out")
        return { inputs: [{audioTarget: ctx.destination, input: 0}], start: (_: number) => { ctx.resume(); } }
    },
    title: "out", inputs: [{ type: AudioParamHandle, name: "in" }], outputs: []
}

export default output;